import React from "react";
import { Box, Typography, Link } from "@mui/material";
import logo from "../assets/CompressedLogo.png";
import resume from "../assets/JHerbertMediaResume.pdf";

function Contact() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row", "row"],
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "black",
        }}
      >
        <Box
          sx={{
            width: ["100%", "25%", "25%"],
            justifyContent: "center",
            alignItems: "flex-start",
            mt: [3, 4, 5],
            mb: [3, 4, 5],
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#00ffff",
              fontSize: "1.5rem",
            }}
          >
            Services
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#ffffff",
            }}
          >
            Graphic Design
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#ffffff",
            }}
          >
            Photography
          </Typography>
        </Box>
        <Box
          sx={{
            width: ["100%", "25%", "25%"],
            mt: [3, 4, 5],
            mb: [3, 4, 5],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo} alt="contact-logo" height="48" width="auto" />
        </Box>
        <Box
          sx={{
            width: ["100%", "25%", "25%"],
            justifyContent: "center",
            alignItems: "flex-start",
            mt: [3, 4, 5],
            mb: [3, 4, 5],
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#00ffff",
              fontSize: "1.5rem",
              textAlign: "top",
            }}
          >
            Contact
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#ffffff",
            }}
          >
            <Link
              underline="hover"
              href="mailto:JonHerbertMedia@gmail.com"
              sx={{ color: "#ffffff" }}
            >
              JonHerbertMedia@gmail.com
            </Link>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#ffffff",
            }}
          >
            <Link
              underline="hover"
              href={resume}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#ffffff" }}
            >
              Resume
            </Link>
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default Contact;
